import {test_art_products_config_en} from './test_config_en';

export const art_products_config_en = {
    ABOUT_ART_PRODUCT: 'About the Artwork',
    CONDITION: 'Condition Status',
    EXPERT_REPORT: 'Expert Report',
    EXPERT_REPORT_LINK: 'View Expert Report',
    INSURANCE_REPORT: 'Insurance Report',
    INSURANCE_REPORT_LINK: 'View Insurance Report',
    EXHIBITION_REPORT: 'Exhibition History',
    EXHIBITION_REPORT_LINK: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
    MORE: 'More information',
    NEW: {
        NAME: 'New Artwork',
        NAME_UPPERCASE: 'NEW ARTWORK',
        YEAR: 'with you!',
        MEDIUM: 'About history and artwork',
        SIZE: 'detailed information coming soon',
        SIGNATURE: '',
        INFO: 'Detailed information about the artwork and the date is coming soon!',
        ARTIST_NAME: '',
        ARTIST_SHORT_NAME: '',
        ARTIST_BORN_DEATH: 'Very soon!',
        ARTIST_ABOUT: '',
        ARTIST_ABOUT_UPPERCASE: '',
        CONDITION: 0,
        EXPERT_REPORT: '',
        INSURANCE_REPORT: '',
        EXHIBITION_REPORT: '',
        IMAGE_DESKTOP: ['./assets/art_products/NEW/0.d.webp?w=600&h=593'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/NEW/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/NEW/0.m.webp?w=450&h=447'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/NEW/0.m.thumbnail.webp'],
        BACKGROUND_DESKTOP: './assets/art_products/NEW/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/NEW/-1.m.thumbnail.webp'
    },
    BOD: {
        NAME: 'Bodrum',
        NAME_UPPERCASE: 'BODRUM',
        YEAR: '2020',
        MEDIUM: 'Acrylic on Canvas',
        SIZE: '60cm x 80cm',
        SIGNATURE: 'Signed',
        INFO: 'Yalçın Gökçebağ  is known as “Anadolu Ressamı”, “Anadolu Düşlerinin Ressamı” and “Resmin Yaşar Kemal\'i”.' +
            ' He reflects Anatolia with its own attitude with its wide perspective, rhythmic layout, detail, use of brushes and colors.' +
            ' He is generally known for his landscape compositions that he fictionalized with wide viewpoints such as Hasat and Bodrum series.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 0, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=0',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Yalçın Gökçebağ',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT YALÇIN GÖKÇEBAĞ',
        ARTIST_NAME: 'Yalçın Gökçebağ',
        ARTIST_SHORT_NAME: 'Y. Gökçebağ',
        ARTIST_BORN_DEATH: '1944-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/BOD/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/BOD/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/BOD/0.d.webp?w=1200&h=954',
            './assets/art_products/BOD/1.d.webp?w=1200&h=892',
            './assets/art_products/BOD/2.d.webp?w=1200&h=966'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/BOD/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/BOD/0.m.webp?w=450&h=357',
            './assets/art_products/BOD/1.m.webp?w=450&h=335',
            './assets/art_products/BOD/2.m.webp?w=450&h=362'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/BOD/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/BOD/-1..webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/BOD/-1.m.thumbnail.webp'
    },
    MAR: {
        NAME: 'Abstrait Marron',
        NAME_UPPERCASE: 'ABSTRAIT MARRON',
        YEAR: '1957',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '25cm x 50cm',
        SIGNATURE: 'Signed',
        INFO: 'Nejad Melih Devrim is among the important painters of his time with his works in the field of contemporary art.' +
            ' Influenced by geographical and cultural elements, the artist followed a path alternating between figurative and abstract.' +
            ' It has an important place in Turkish art, especially with its late period abstract paintings.' +
            ' He takes place in the lyrical abstract and partially geometric abstract,' +
            ' which is the European counterpart of the movement known as Abstract Expressionism in America.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 1, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=1',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Nejad Melih Devrim',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT NEJAD MELİH DEVRİM',
        ARTIST_NAME: 'Nejad Melih Devrim',
        ARTIST_SHORT_NAME: 'N. M. Devrim',
        ARTIST_BORN_DEATH: '1923-1995',
        CONDITION: 4.5,
        EXPERT_REPORT: './assets/art_products/MAR/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MAR/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/MAR/0.d.webp?w=1200&h=666',
            './assets/art_products/MAR/1.d.webp?w=1200&h=588',
            './assets/art_products/MAR/2.d.webp?w=1200&h=677'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MAR/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MAR/0.m.webp?w=450&h=250',
            './assets/art_products/MAR/1.m.webp?w=450&h=221',
            './assets/art_products/MAR/2.m.webp?w=450&h=254'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MAR/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MAR/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MAR/-1.m.thumbnail.webp'
    },
    PGS: {
        NAME: 'Pegasus\'un Bulutu',
        NAME_UPPERCASE: 'PEGASUS\'UN BULUTU',
        YEAR: '2015',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'Signed',
        INFO: 'Ahmet Güneştekin is known as one of the most important figures of the art in the 21st century in Turkey.' +
            ' He produces works in the fields of painting, conceptual art and sculpture.' +
            ' This self-taught artist uses geographical elements and Anatolian, Greek and Mesopotamian legends in his works.' +
            ' Standing out with traditional and contemporary references, Güneştekin abandoned figurative work in the early 2000s' +
            ' and adopted an abstract style that focuses on narrative.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 2, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=2',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Ahmet Güneştekin',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT AHMET GÜNEŞTEKİN',
        ARTIST_NAME: 'Ahmet Güneştekin',
        ARTIST_SHORT_NAME: 'A. Güneştekin',
        ARTIST_BORN_DEATH: '1966-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/PGS/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/PGS/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/PGS/0.d.webp?w=1178&h=1200',
            './assets/art_products/PGS/1.d.webp?w=1200&h=1191'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/PGS/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/PGS/0.m.webp?w=442&h=450',
            './assets/art_products/PGS/1.m.webp?w=450&h=447'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/PGS/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/PGS/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/PGS/-1.m.thumbnail.webp'
    },
    ORC: {
        NAME: 'Orchestra',
        NAME_UPPERCASE: 'ORCHESTRA',
        YEAR: '2011',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'Signed',
        INFO: 'Adnan Turani is a key figure of art and art academies of Turkey and one of the the most important representatives of abstract-oriented painting in Turkey.' +
            ' Turani showed an abstract understanding in his early works and used figures and nature recently,' +
            ' but his approach to paintings continued to be based on an abstract fiction and the expression of form.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 3, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=3',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Adnan Turani',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ADNAN TURANİ',
        ARTIST_NAME: 'Adnan Turani',
        ARTIST_SHORT_NAME: 'A. Turani',
        ARTIST_BORN_DEATH: '1925-2016',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ORC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ORC/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/ORC/0.d.webp?w=1200&h=1182',
            './assets/art_products/ORC/1.d.webp?w=1200&h=1156'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ORC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/ORC/0.m.webp?w=450&h=443',
            './assets/art_products/ORC/1.m.webp?w=450&h=434'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ORC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ORC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ORC/-1.m.thumbnail.webp'
    },
    IST: {
        NAME: 'Süleymaniye\'den İstanbul\'a',
        NAME_UPPERCASE: 'SÜLEYMANİYE\'DEN İSTANBUL\'A',
        YEAR: '2021',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'Signed',
        INFO: 'Devrim Erbil is a painter and academic who has participated in many national and international exhibitions and has many awards.' +
            'He has produced works with many mediums such as oil painting, printing, stained glass and mosaic.' +
            'Devrim Erbil\'s art can be evaluated within the local trends that want to place contemporary art on a foundation connected with tradition.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 4, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=4',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Devrim Erbil',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT DEVRİM ERBİL',
        ARTIST_NAME: 'Devrim Erbil',
        ARTIST_SHORT_NAME: 'D. Erbil',
        ARTIST_BORN_DEATH: '1937-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/IST/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/IST/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/IST/0.d.webp?w=1200&h=1193',
            './assets/art_products/IST/1.d.webp?w=1200&h=1200',
            './assets/art_products/IST/2.d.webp?w=1200&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/IST/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/IST/0.m.webp?w=450&h=450',
            './assets/art_products/IST/1.m.webp?w=450&h=450',
            './assets/art_products/IST/2.m.webp?w=450&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/IST/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/IST/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/IST/-1.m.thumbnail.webp'
    },
    APT: {
        NAME: 'Apartman',
        NAME_UPPERCASE: 'APARTMAN',
        YEAR: '1977',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '129cm x 100cm',
        SIGNATURE: 'Signed',
        INFO: 'Nuri Abaç, who relates his paintings with traditions, daily life and Anatolian legends, and presents them with a unique decorative surrealism,' +
            ' became one of the important painters of 20th century Turkish art.' +
            ' The prominent feature of his style is that he adopts an approach with Karagöz-Hacivat aesthetic and often includes musical elements.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 5, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=5',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Nuri Abaç',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT NURİ ABAÇ',
        ARTIST_NAME: 'Nuri Abaç',
        ARTIST_SHORT_NAME: 'N. Abaç',
        ARTIST_BORN_DEATH: '1926-2008',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/APT/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/APT/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/APT/0.d.webp?w=979&h=1200',
            './assets/art_products/APT/1.d.webp?w=942&h=1200',
            './assets/art_products/APT/2.d.webp?w=966&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/APT/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/APT/0.m.webp?w=368&h=450',
            './assets/art_products/APT/1.m.webp?w=353&h=450',
            './assets/art_products/APT/2.m.webp?w=362&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/APT/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/APT/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/APT/-1.m.thumbnail.webp'
    },
    SE1: {
        NAME: 'Sauna Emerging 1',
        NAME_UPPERCASE: 'SAUNA EMERGING 1',
        YEAR: '',
        MEDIUM: '',
        SIZE: 'Various',
        SIGNATURE: 'Signed',
        INFO: 'The “Sauna Emerging 1” bundle, created within the framework of the collaboration of Artiox and Art.Ist Sauna, consists of the works of 11 young and independent artists.' +
            ' While investing in the artworks produced by promising and carefully selected artists, you will also support them with this bundle.' +
            ' As a result of a long and detailed study, the selection committee formed by Art.Ist Sauna determined the names to be included in the bundle' +
            ' and focused on selecting the artists based on their past performances and producing works consistently.' +
            ' The works in the bundle will be constantly exhibited in the exhibition area of UniqExpo and art lovers will have the opportunity to spend time with the artists at various events.' +
            ' Thus, while keeping in contact with the artist and art lovers more, we will also have the opportunity to regularly monitor the progress of these valuable artists.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 6, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=6',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Sauna Emerging 1 Bundle',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT SAUNA EMERGING 1 BUNDLE',
        ARTIST_NAME: 'Sauna Emerging 1 Artists',
        ARTIST_SHORT_NAME: 'Sauna Emerging 1',
        ARTIST_BORN_DEATH: ' - ',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SE1/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SE1/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: [
            './assets/art_products/SE1/0.d.webp?w=1201&h=924',
            './assets/art_products/SE1/1.d.webp?w=1181&h=1200',
            './assets/art_products/SE1/2.d.webp?w=901&h=1200',
            './assets/art_products/SE1/3.d.webp?w=1202&h=910',
            './assets/art_products/SE1/4.d.webp?w=907&h=1199',
            './assets/art_products/SE1/5.d.webp?w=1193&h=1201',
            './assets/art_products/SE1/6.d.webp?w=1201&h=898',
            './assets/art_products/SE1/7.d.webp?w=1180&h=1200',
            './assets/art_products/SE1/8.d.webp?w=1200&h=1100',
            './assets/art_products/SE1/9.d.webp?w=1200&h=1200',
            './assets/art_products/SE1/10.d.webp?w=1200&h=1199',
            './assets/art_products/SE1/11.d.webp?w=1023&h=1199',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SE1/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/SE1/0.m.webp?w=454&h=351',
            './assets/art_products/SE1/1.m.webp?w=445&h=450',
            './assets/art_products/SE1/2.m.webp?w=341&h=448',
            './assets/art_products/SE1/3.m.webp?w=449&h=343',
            './assets/art_products/SE1/4.m.webp?w=335&h=455',
            './assets/art_products/SE1/5.m.webp?w=499&h=499',
            './assets/art_products/SE1/6.m.webp?w=451&h=343',
            './assets/art_products/SE1/7.m.webp?w=445&h=454',
            './assets/art_products/SE1/8.m.webp?w=448&h=412',
            './assets/art_products/SE1/9.m.webp?w=450&h=450',
            './assets/art_products/SE1/10.m.webp?w=452&h=451',
            './assets/art_products/SE1/11.m.webp?w=381&h=447',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SE1/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SE1/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SE1/-1.m.thumbnail.webp'
    },
    BUG: {
        NAME: 'Böcek',
        NAME_UPPERCASE: 'BÖCEK',
        YEAR: '2021',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '135cm x 135cm',
        SIGNATURE: 'Signed',
        INFO: 'Ergin İnan is a painter who has participated in many internationally recognized exhibitions and was awarded an award.' +
            ' The artist, who has an unconventional approach in contemporary Turkish art, has made conceptual studies using mixed media and printing techniques in his works.' +
            ' Abstracted human figures, writing images and insect depictions can be given as examples to the images that are common in Ergin İnan\'s works.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: ' About Ergin İnan',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ERGİN İNAN',
        ARTIST_NAME: 'Ergin İnan',
        ARTIST_SHORT_NAME: 'E. İnan',
        ARTIST_BORN_DEATH: '1943-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/BUG/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/BUG/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/BUG/0.d.webp?w=1158&h=1200',
            './assets/art_products/BUG/1.d.webp?w=1200&h=1108'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/BUG/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/BUG/0.m.webp?w=434&h=450',
            './assets/art_products/BUG/1.m.webp?w=450&h=416'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/BUG/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/BUG/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/BUG/-1.m.thumbnail.webp'
    },
    FACE: {
        NAME: 'Yüzler',
        NAME_UPPERCASE: 'YÜZLER',
        YEAR: '2023',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '100cm x 150cm',
        SIGNATURE: 'Signed',
        INFO: 'Ergin İnan is a painter who has participated in many internationally recognized exhibitions and was awarded an award.' +
            ' The artist, who has an unconventional approach in contemporary Turkish art, has made conceptual studies using mixed media and printing techniques in his works.' +
            ' Abstracted human figures, writing images and insect depictions can be given as examples to the images that are common in Ergin İnan\'s works.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: ' About Ergin İnan',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ERGİN İNAN',
        ARTIST_NAME: 'Ergin İnan',
        ARTIST_SHORT_NAME: 'E. İnan',
        ARTIST_BORN_DEATH: '1943-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/FACE/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/FACE/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/FACE/0.d.webp?w=1200&h=809',
            './assets/art_products/FACE/1.d.webp?w=1200&h=796'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/FACE/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/FACE/0.m.webp?w=450&h=303',
            './assets/art_products/FACE/1.m.webp?w=450&h=298'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/FACE/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/FACE/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/FACE/-1.m.thumbnail.webp'
    },
    RET: {
        NAME: 'Retrospective',
        NAME_UPPERCASE: 'RETROSPEKTIVE',
        YEAR: '2021',
        MEDIUM: 'Acrylic on Canvas',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'Signed',
        INFO: 'Adnan Çoker, who has an important place in 20th century Turkish art, is best known for creating an original aesthetic style by transforming Byzantine, Seljuk and Ottoman architectural forms into pictorial contours.' +
            ' The artist, who produced works in this line after the 1970s, works in his paintings, which he describes as pattern-form, in an abstract spatial dimension on black.' +
            ' The harmony created by the motif of the pointed arched door and window opening the interior space of the monumental architecture to the outside world, is based on a molded form in the words of the artist.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 8, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=8',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Adnan Çoker',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ADNAN ÇOKER',
        ARTIST_NAME: 'Adnan Çoker',
        ARTIST_SHORT_NAME: 'A. Çoker',
        ARTIST_BORN_DEATH: '1927-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/RET/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/RET/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/RET/0.d.webp?w=1200&h=1179',
            './assets/art_products/RET/1.d.webp?w=1200&h=1168'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/RET/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/RET/0.m.webp?w=450&h=442',
            './assets/art_products/RET/1.m.webp?w=450&h=438'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/RET/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/RET/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/RET/-1.m.thumbnail.webp'
    },
    TETE: {
        NAME: 'Tete d’Histrion (Le Danseur)',
        NAME_UPPERCASE: 'TETE D’HISTRION (LE DANSEUR)',
        YEAR: '1965',
        MEDIUM: 'Linocut',
        SIZE: '75.4cm x 62.2cm',
        SIGNATURE: 'Signed',
        INFO: 'Pablo Picasso, one of the greatest and most influential artists of the 20th century, is born in Malaga, Spain.' +
            'Picasso’s father was a professor of drawing, and he bred his son for a career in academic art.' +
            ' Picasso had his first exhibit at age 13 and later quit art school so he could experiment full-time with modern art styles.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 9, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=9',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Pablo Picasso',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT PABLO PICASSO',
        ARTIST_NAME: 'Pablo Picasso',
        ARTIST_SHORT_NAME: 'P. Picasso',
        ARTIST_BORN_DEATH: '1881-1973',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/TETE/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/TETE/insurance_report.pdf',
        EXHIBITION_REPORT: '<a class="text-info" href="https://www.iaafistanbul.com/" target="_blank" rel="noreferrer">3-7 November IAAF</a>',
        IMAGE_DESKTOP: ['./assets/art_products/TETE/0.d.webp?w=1062&h=1200',
            './assets/art_products/TETE/1.d.webp?w=1062&h=1200',
            './assets/art_products/TETE/2.d.webp?w=1062&h=1200',
            './assets/art_products/TETE/3.d.webp?w=1069&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/TETE/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/TETE/0.m.webp?w=398&h=450',
            './assets/art_products/TETE/1.m.webp?w=398&h=450',
            './assets/art_products/TETE/2.m.webp?w=398&h=450',
            './assets/art_products/TETE/3.m.webp?w=401&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/TETE/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/TETE/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/TETE/-1.m.thumbnail.webp'
    },
    NAI: {
        NAME: 'La Danseuse Naine',
        NAME_UPPERCASE: 'LA DANSEUSE NAINE',
        YEAR: '1966',
        MEDIUM: 'Lithography',
        SIZE: '75.5cm x 55cm',
        SIGNATURE: 'Signed',
        INFO: 'Pablo Picasso, one of the greatest and most influential artists of the 20th century, is born in Malaga, Spain.' +
            'Picasso’s father was a professor of drawing, and he bred his son for a career in academic art.' +
            ' Picasso had his first exhibit at age 13 and later quit art school so he could experiment full-time with modern art styles.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 9, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=9',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Pablo Picasso',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT PABLO PICASSO',
        ARTIST_NAME: 'Pablo Picasso',
        ARTIST_SHORT_NAME: 'P. Picasso',
        ARTIST_BORN_DEATH: '1881-1973',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/NAI/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/NAI/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/NAI/0.d.webp?w=927&h=1200',
            './assets/art_products/NAI/1.d.webp?w=927&h=1200',
            './assets/art_products/NAI/2.d.webp?w=927&h=1200',
            './assets/art_products/NAI/3.d.webp?w=942&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/NAI/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/NAI/0.m.webp?w=348&h=450',
            './assets/art_products/NAI/1.m.webp?w=348&h=450',
            './assets/art_products/NAI/2.m.webp?w=348&h=450',
            './assets/art_products/NAI/3.m.webp?w=353&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/NAI/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/NAI/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/NAI/-1.m.thumbnail.webp'
    },
    ORAN: {
        NAME: 'Untitled',
        NAME_UPPERCASE: 'UNTITLED',
        YEAR: '2009',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '190cm x 250cm',
        SIGNATURE: 'Signed',
        INFO: 'Ahmet Oran, known for his abstract works, was born in 1957 in Çanakkale.' +
            ' The artist, who entered the Istanbul State Academy of Fine Arts in 1977, studied painting, glass painting and graphics at the Vienna Applied Fine Arts College while he was a student there.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 10, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=10',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Ahmet Oran',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT AHMET ORAN',
        ARTIST_NAME: 'Ahmet Oran',
        ARTIST_SHORT_NAME: 'A. Oran',
        ARTIST_BORN_DEATH: '1957-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ORAN/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ORAN/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: [
            './assets/art_products/ORAN/0.d.webp?w=1200&h=903',
            './assets/art_products/ORAN/1.d.webp?w=1200&h=1127',
            './assets/art_products/ORAN/2.d.webp?w=1200&h=901',
            './assets/art_products/ORAN/3.d.webp?w=1200&h=893',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ORAN/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/ORAN/0.m.webp?w=450&h=339',
            './assets/art_products/ORAN/1.m.webp?w=450&h=423',
            './assets/art_products/ORAN/2.m.webp?w=450&h=338',
            './assets/art_products/ORAN/3.m.webp?w=450&h=335',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ORAN/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ORAN/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ORAN/-1.m.thumbnail.webp'
    },
    SUM: {
        NAME: 'Summer',
        NAME_UPPERCASE: 'SUMMER',
        YEAR: '-',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '158cm x 74cm',
        SIGNATURE: 'Signed',
        INFO: 'Born in Büyükada on January 7, 1901, painter Fahrelnissa Zeid is from the Kabaağaçlı family, which has trained many artists.' +
            ' Although the family has a military background, members of the Kabaağaçlı family have written their names in history in different branches of art.' +
            ' Famous painter Fahrelnissa Zeid, sister of Halikarnas Balıkçısı Cevat Şakir and painter Aliye Berger, mother of actress Şirin Devrim and painter Nejad Devrim,' +
            ' and aunt of Turkey’s first female ceramic artist Füreya Koral, is a talented Turkish woman who made her name known to the whole world with her art and character.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 11, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=11',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Fahrelnissa Zeid',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT FAHRELNISSA ZEID',
        ARTIST_NAME: 'Fahrelnissa Zeid',
        ARTIST_SHORT_NAME: 'F. Zeid',
        ARTIST_BORN_DEATH: '1901-1991',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SUM/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SUM/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: [
            './assets/art_products/SUM/0.d.webp?w=633&h=1200',
            './assets/art_products/SUM/1.d.webp?w=555&h=1200',
            './assets/art_products/SUM/2.d.webp?w=633&h=1200',
            './assets/art_products/SUM/3.d.webp?w=724&h=1200',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SUM/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/SUM/0.m.webp?w=238&h=450',
            './assets/art_products/SUM/1.m.webp?w=208&h=450',
            './assets/art_products/SUM/2.m.webp?w=237&h=450',
            './assets/art_products/SUM/3.m.webp?w=271&h=450',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SUM/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SUM/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SUM/-1.m.thumbnail.webp'
    },
    TRUE: {
        NAME: 'True Love',
        NAME_UPPERCASE: 'TRUE LOVE',
        YEAR: '2018',
        MEDIUM: 'Acrylic on Canvas',
        SIZE: '200cm x 200cm',
        SIGNATURE: 'Signed',
        INFO: 'Haluk Akakçe, one of the most prominent names in Turkish contemprary art. Extraordinary, creative and genius…',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 12, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=12',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Haluk Akakçe',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT HALUK AKAKÇE',
        ARTIST_NAME: 'Haluk Akakçe',
        ARTIST_SHORT_NAME: 'H. Akakçe',
        ARTIST_BORN_DEATH: '1970-2023',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/TRUE/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/TRUE/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/TRUE/0.d.webp?w=1073&h=1200',
            './assets/art_products/TRUE/1.d.webp?w=1073&h=1200',
            './assets/art_products/TRUE/2.d.webp?w=1075&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/TRUE/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/TRUE/0.m.webp?w=403&h=450',
            './assets/art_products/TRUE/1.m.webp?w=402&h=450',
            './assets/art_products/TRUE/2.m.webp?w=403&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/TRUE/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/TRUE/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/TRUE/-1.m.thumbnail.webp'
    },
    ULC: {
        NAME: 'Untitled',
        NAME_UPPERCASE: 'UNTITLED',
        YEAR: '-',
        MEDIUM: 'Acrylic on Canvas',
        SIZE: '100cm x 100cm',
        SIGNATURE: 'Signed',
        INFO: 'Born in 1931, Ömer Uluç is a world-renowned Turkish painter.' +
            ' He opened more than 30 personal exhibitions in different countries and impressed the whole world with the works he presented in the organizations he participated in.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 13, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=13',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Ömer Uluç',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ÖMER ULUÇ',
        ARTIST_NAME: 'Ömer Uluç',
        ARTIST_SHORT_NAME: 'Ö. Uluç',
        ARTIST_BORN_DEATH: '1931-2010',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ULC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ULC/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: [
            './assets/art_products/ULC/0.d.webp?w=1200&h=903',
            './assets/art_products/ULC/1.d.webp?w=1200&h=1127',
            './assets/art_products/ULC/2.d.webp?w=1200&h=901',
            './assets/art_products/ULC/3.d.webp?w=1200&h=893',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ULC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/ULC/0.m.webp?w=450&h=339',
            './assets/art_products/ULC/1.m.webp?w=450&h=423',
            './assets/art_products/ULC/2.m.webp?w=450&h=338',
            './assets/art_products/ULC/3.m.webp?w=450&h=335',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ULC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ULC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ULC/-1.m.thumbnail.webp'
    },
    IYM: {
        NAME: 'Untitled',
        NAME_UPPERCASE: 'UNTITLED',
        YEAR: '-',
        MEDIUM: 'Oil Paint on Duralite',
        SIZE: '48cm x 38cm',
        SIGNATURE: 'Signed',
        INFO: 'Inspired by the eyes and face of his older sister, whom he witnessed as a child,' +
            ' artist Nuri İyem is a painter of \'shy, beautiful and melancholic\' faces that have become an iconic and integral part of his art.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 14, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=14',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Nuri İyem',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT NURİ İYEM',
        ARTIST_NAME: 'Nuri İyem',
        ARTIST_SHORT_NAME: 'N. İyem',
        ARTIST_BORN_DEATH: '1915-2005',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/IYM/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/IYM/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: [
            './assets/art_products/IYM/0.d.webp?w=1000&h=1200',
            './assets/art_products/IYM/1.d.webp?w=1013&h=1200',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/IYM/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/IYM/0.m.webp?w=375&h=450',
            './assets/art_products/IYM/1.m.webp?w=380&h=450',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/IYM/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/IYM/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/IYM/-1.m.thumbnail.webp'
    },
    MATA: {
        NAME: 'Untitled',
        NAME_UPPERCASE: 'UNTITLED',
        YEAR: '2012',
        MEDIUM: 'Mixed Media On Canvas',
        SIZE: '97cm x 130 cm',
        SIGNATURE: 'Signed',
        INFO: 'Mustafa Ata, born in Trabzon on March 18, 1945,' +
            ' studied at the Adnan Çoker Studio of the Painting Department of Mimar Sinan Fine Arts Faculty (then Istanbul State Academy of Fine Arts).',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 15, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=15',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Mustafa Ata',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT MUSTAFA ATA',
        ARTIST_NAME: 'Mustafa Ata',
        ARTIST_SHORT_NAME: 'M. Ata',
        ARTIST_BORN_DEATH: '1945-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/MATA/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MATA/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/MATA/0.d.webp?w=1200&h=900',
            './assets/art_products/MATA/1.d.webp?w=1200&h=900',
            './assets/art_products/MATA/2.d.webp?w=1200&h=906'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MATA/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MATA/0.m.webp?w=450&h=338',
            './assets/art_products/MATA/1.m.webp?w=450&h=338',
            './assets/art_products/MATA/2.m.webp?w=450&h=340'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MATA/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MATA/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MATA/-1.m.thumbnail.webp'
    },
    MAVI: {
        NAME: 'Mavi Şiir',
        NAME_UPPERCASE: 'MAVİ ŞİİR',
        YEAR: '2022',
        MEDIUM: 'Tuval Üzerine Yağlı Boya',
        SIZE: '105cm x 105cm',
        SIGNATURE: 'İmzalı',
        INFO: 'Devrim Erbil is a painter and academic who has participated in many national and international exhibitions and has many awards.' +
            'He has produced works with many mediums such as oil painting, printing, stained glass and mosaic.' +
            'Devrim Erbil\'s art can be evaluated within the local trends that want to place contemporary art on a foundation connected with tradition.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 4, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=4',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Devrim Erbil',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT DEVRİM ERBİL',
        ARTIST_NAME: 'Devrim Erbil',
        ARTIST_SHORT_NAME: 'D. Erbil',
        ARTIST_BORN_DEATH: '1937-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/IST/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/IST/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/MAVI/0.d.webp?w=1200&h=1196',
            './assets/art_products/MAVI/1.d.webp?w=1200&h=1196',
            './assets/art_products/MAVI/2.d.webp?w=1168&h=1200',
            './assets/art_products/MAVI/3.d.webp?w=1200&h=442'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MAVI/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MAVI/0.m.webp?w=450&h=448',
            './assets/art_products/MAVI/1.m.webp?w=450&h=448',
            './assets/art_products/MAVI/2.m.webp?w=438&h=450',
            './assets/art_products/MAVI/3.m.webp?w=450&h=166'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MAVI/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MAVI/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MAVI/-1.m.thumbnail.webp'
    },
    SOY: {
        NAME: 'Soyağacı',
        NAME_UPPERCASE: 'SOYAĞACI',
        YEAR: '2014',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '180cm x 180cm',
        SIGNATURE: 'Signed',
        INFO: 'Özdemir Altan, born in Giresun in 1931, graduated from Istanbul State Academy of Fine Arts in 1956.' +
            ' Following his graduation in 1956, he began working as an academician at the same school and continued in this position until his retirement in 1988.' +
            ' As a prominent artist, he pioneered avant-garde thinking, Pop art, and postmodernism in Turkey.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 16, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=16',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Özdemir Altan',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ÖZDEMİR ALTAN',
        ARTIST_NAME: 'Özdemir Altan',
        ARTIST_SHORT_NAME: 'Ö. Altan',
        ARTIST_BORN_DEATH: '1931-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SOY/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SOY/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/SOY/0.d.webp?w=1200&h=1200',
            './assets/art_products/SOY/1.d.webp?w=1200&h=1176'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SOY/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/SOY/0.m.webp?w=450&h=450',
            './assets/art_products/SOY/1.m.webp?w=450&h=441'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SOY/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SOY/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SOY/-1.m.thumbnail.webp'
    },
    DIP: {
        NAME: 'Dip Dalga',
        NAME_UPPERCASE: 'DİP DALGA',
        YEAR: '2023',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '120x150 cm - Diptych (120x90+120x60 cm)',
        SIGNATURE: 'Signed',
        INFO: 'Ahmet Yeşil was born in Mersin in 1954 and grew up in this city. He received his artistic education from talented artists such as Painter Nuri Abaç, İlhan Çevik, and Sculptor Ernür Tüzün.' +
            ' While the artist\'s works are included in many private collections in Turkey, they have been represented in foreign collections in Germany, America, Canada, the Netherlands, and England.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 17, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=17',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Ahmet Yeşil',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT AHMET YEŞİL',
        ARTIST_NAME: 'Ahmet Yeşil',
        ARTIST_SHORT_NAME: 'A. Yeşil',
        ARTIST_BORN_DEATH: '1954-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/DIP/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/DIP/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: [
            './assets/art_products/DIP/0.d.webp?w=1200&h=941',
            './assets/art_products/DIP/1.d.webp?w=1200&h=954',
            './assets/art_products/DIP/2.d.webp?w=1200&h=911',
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/DIP/0.d.thumbnail.webp'],
        IMAGE_MOBILE: [
            './assets/art_products/DIP/0.m.webp?w=450&h=353',
            './assets/art_products/DIP/1.m.webp?w=450&h=358',
            './assets/art_products/DIP/2.m.webp?w=450&h=342',
        ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/DIP/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/DIP/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/DIP/-1.m.thumbnail.webp'
    },
    ASOS: {
        NAME: 'Assos',
        NAME_UPPERCASE: 'ASSOS',
        YEAR: '2019',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '64cm x 54cm',
        SIGNATURE: 'Signed',
        INFO: 'Mehmet Güleryüz is considered one of the prominent contemporary Turkish artists, and his works can be found in many art museums and collections in Turkey and around the world. ' +
            'He frequently focuses on human figures in his artworks and reflects the emotional experiences of ordinary people, such as happiness, sadness, and anger.' +
            ' He employs a naive style and avoids complex compositions, using a simple and understandable language in his art.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 18, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Mehmet Güleryüz',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT MEHMET GÜLERYÜZ',
        ARTIST_NAME: 'Mehmet Güleryüz',
        ARTIST_SHORT_NAME: 'M. Güleryüz' ,
        ARTIST_BORN_DEATH: '1938-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/ASOS/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/ASOS/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/ASOS/0.d.webp?w=1038&h=1200',
            './assets/art_products/ASOS/1.d.webp?w=942&h=1200',
            './assets/art_products/ASOS/2.d.webp?w=960&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/ASOS/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/ASOS/0.m.webp?w=390&h=451',
            './assets/art_products/ASOS/1.m.webp?w=354&h=450',
            './assets/art_products/ASOS/2.m.webp?w=360&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/ASOS/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/ASOS/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/ASOS/-1.m.thumbnail.webp'
    },
    SURF: {
        NAME: 'Unlimited Surface',
        NAME_UPPERCASE: 'Unlimited Surface',
        YEAR: '1995',
        MEDIUM: 'Oil Painting On Canvas',
        SIZE: '146cm x 180cm',
        SIGNATURE: 'Signed',
        INFO: 'Mustafa Ata, born in Trabzon on March 18, 1945,' +
            ' studied at the Adnan Çoker Studio of the Painting Department of Mimar Sinan Fine Arts Faculty (then Istanbul State Academy of Fine Arts).',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 15, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=15',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Mustafa Ata',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT MUSTAFA ATA',
        ARTIST_NAME: 'Mustafa Ata',
        ARTIST_SHORT_NAME: 'M. Ata',
        ARTIST_BORN_DEATH: '1945-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SURF/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SURF/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/SURF/0.d.webp?w=1200&h=900',
            './assets/art_products/SURF/1.d.webp?w=1200&h=884',
            './assets/art_products/SURF/2.d.webp?w=1176&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SURF/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/SURF/0.m.webp?w=450&h=337',
            './assets/art_products/SURF/1.m.webp?w=450&h=332',
            './assets/art_products/SURF/2.m.webp?w=441&h=450', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SURF/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SURF/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SURF/-1.m.thumbnail.webp'
    },
    SET: {
        NAME: 'Soyut Kompozisyon',
        NAME_UPPERCASE: 'SOYUT KOMPOZİSYON',
        YEAR: '-',
        MEDIUM: 'Acrylic Paint On Canvas',
        SIZE: '162cm x 130cm',
        SIGNATURE: 'Signed',
        INFO: 'Born in 1915 in Istanbul, in the Cağaloğlu district, Selim Turan was the son of Prof. Dr. Hüseyinzade Ali Turan of Azeri-Caucasian origin and Edhiye Hanım, the daughter of Çerkez Süvari Zabiti Şemsettin Bey.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 19, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=15',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Selim Turan',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT SELİM TURAN',
        ARTIST_NAME: 'Selim Turan',
        ARTIST_SHORT_NAME: 'S. Turan',
        ARTIST_BORN_DEATH: '1915 - 1994',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/SET/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/SET/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/SET/0.d.webp?w=972&h=1200',
            './assets/art_products/SET/1.d.webp?w=963&h=1200',
            './assets/art_products/SET/2.d.webp?w=894&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/SET/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/SET/0.m.webp?w=365&h=450',
            './assets/art_products/SET/1.m.webp?w=361&h=450',
            './assets/art_products/SET/2.m.webp?w=336&h=450', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/SET/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/SET/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/SET/-1.m.thumbnail.webp'
    },
    PAK: {
        NAME: 'Suretler',
        NAME_UPPERCASE: 'SURETLER',
        YEAR: '2023',
        MEDIUM: 'Oil Paint On Canvas',
        SIZE: '140cm x 160cm',
        SIGNATURE: 'Signed',
        INFO: 'Şahin Paksoy, born in 1952, is a distinct figure in Turkish painting and ceramics. He began his art education in 1973 at the Ceramics Department of the Istanbul State Academy of Fine Arts, graduating in 1980.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 20, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=20',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Şahin Paksoy',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ŞAHİN PAKSOY',
        ARTIST_NAME: 'Şahin Paksoy',
        ARTIST_SHORT_NAME: 'Ş. Paksoy',
        ARTIST_BORN_DEATH: '1952 - ',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/PAK/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/PAK/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/PAK/0.d.webp?w=1059&h=1200',
            './assets/art_products/PAK/1.d.webp?w=1051&h=1200',
            './assets/art_products/PAK/2.d.webp?w=1170&h=1200', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/PAK/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/PAK/0.m.webp?w=397&h=450',
            './assets/art_products/PAK/1.m.webp?w=394&h=450',
            './assets/art_products/PAK/2.m.webp?w=450&h=439', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/PAK/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/PAK/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/PAK/-1.m.thumbnail.webp'
    },

    TOC: {
        NAME: 'Touch of Class',
        NAME_UPPERCASE: 'TOUCH OF CLASS',
        YEAR: '1976',
        MEDIUM: 'Acrylic Paint On Canvas',
        SIZE: '60cm x 73cm',
        SIGNATURE: 'Signed',
        INFO: 'Burhan Doğançay was born in Istanbul in 1929. Growing up surrounded by art thanks to his father, the well-known painter and map officer Adil Doğançay, and painter Arif Kaptan, he developed a deep connection with art from an early age. After graduating from the Faculty of Law at Ankara University, he chose art over an academic career, continuing his painting studies while completing his doctorate in economics in Paris.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 21, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=21',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Burhan Doğançay',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT BURHAN DOĞANÇAY',
        ARTIST_NAME: 'Burhan Doğançay',
        ARTIST_SHORT_NAME: 'B. Doğançay',
        ARTIST_BORN_DEATH: '1929 - 2013',
        CONDITION: 4.5,
        EXPERT_REPORT: './assets/art_products/TOC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/TOC/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/TOC/0.d.webp?w=1200&h=998',
            './assets/art_products/TOC/1.d.webp?w=1200&h=991',
            './assets/art_products/TOC/2.d.webp?w=1200&h=990', ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/TOC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/TOC/0.m.webp?w=450&h=374',
            './assets/art_products/TOC/1.m.webp?w=350&h=372',
            './assets/art_products/TOC/2.m.webp?w=450&h=371', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/TOC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/TOC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/TOC/-1.m.thumbnail.webp'
    },
    MES: {
        NAME: 'Mesnevi',
        NAME_UPPERCASE: 'MESNEVİ',
        YEAR: '2008',
        MEDIUM: 'Mixed Technic on Wood',
        SIZE: '255cm x 94cm',
        SIGNATURE: 'Signed',
        INFO: 'Ergin İnan is a painter who has participated in many internationally recognized exhibitions and was awarded an award.' +
          ' The artist, who has an unconventional approach in contemporary Turkish art, has made conceptual studies using mixed media and printing techniques in his works.' +
          ' Abstracted human figures, writing images and insect depictions can be given as examples to the images that are common in Ergin İnan\'s works.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 7, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=7',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: ' About Ergin İnan',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ERGİN İNAN',
        ARTIST_NAME: 'Ergin İnan',
        ARTIST_SHORT_NAME: 'E. İnan',
        ARTIST_BORN_DEATH: '1943-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/MES/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/MES/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/MES/0.d.webp?w=482&h=1200',
            './assets/art_products/MES/1.d.webp?w=449&h=1200'
        ],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/MES/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/MES/0.m.webp?w=181&h=450',
            './assets/art_products/MES/1.m.webp?w=168&h=449', ],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/MES/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/MES/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/MES/-1.m.thumbnail.webp'
    },
    NUH: {
        NAME: 'Nuh\'un Gemisi',
        NAME_UPPERCASE: 'NUH\'UN GEMISI',
        YEAR: '2023',
        MEDIUM: 'Oil Paint on Canvas',
        SIZE: '180cm x 250cm',
        SIGNATURE: 'Signed',
        INFO: 'Ertuğrul Ateş was born in Adana in 1954. His artistic journey began in high school with Duran Karaca, and he continued his education at the Gazi Education Institute\'s Department of Painting and Work, graduating in 1976.' +
          ' After completing his academic studies, he moved to London in 1977, where he furthered his art education at the Kingsway Princeton College and the Bethnal Green Institute.' +
          ' In 1987, Ateş relocated to the United States, settling in New York, where he continued his work until 2006 before returning to Turkey.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 22, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=22',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Ertuğrul Ateş',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ERTUĞRUL ATEŞ',
        ARTIST_NAME: 'Ertuğrul Ateş',
        ARTIST_SHORT_NAME: 'E. Ateş',
        ARTIST_BORN_DEATH: '1954-',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/NUH/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/NUH/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/NUH/0.d.webp?w=1200&h=848', './assets/art_products/NUH/1.d.webp?w=1200&h=1094'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/NUH/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/NUH/0.m.webp?w=450&h=318', './assets/art_products/NUH/0.m.webp?w=450&h=410'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/NUH/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/NUH/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/NUH/-1.m.thumbnail.webp'
    },
    BURC: {
        NAME: 'Sarıda Burçlar',
        NAME_UPPERCASE: 'SARIDA BURÇLAR',
        YEAR: '2000',
        MEDIUM: 'Acrylic on Canvas',
        SIZE: '140cm x 140cm',
        SIGNATURE: 'Signed',
        INFO: 'Adnan Çoker, who has an important place in 20th century Turkish art, is best known for creating an original aesthetic style by transforming Byzantine, Seljuk and Ottoman architectural forms into pictorial contours.' +
          ' The artist, who produced works in this line after the 1970s, works in his paintings, which he describes as pattern-form, in an abstract spatial dimension on black.' +
          ' The harmony created by the motif of the pointed arched door and window opening the interior space of the monumental architecture to the outside world, is based on a molded form in the words of the artist.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue.',
            LINK: {url: '/about-artist', artist_announcement_index: 8, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=8',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Adnan Çoker',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ADNAN ÇOKER',
        ARTIST_NAME: 'Adnan Çoker',
        ARTIST_SHORT_NAME: 'A. Çoker',
        ARTIST_BORN_DEATH: '1927 - 2022',
        CONDITION: 5,
        EXPERT_REPORT: './assets/art_products/BURC/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/BURC/insurance_report.pdf',
        EXHIBITION_REPORT: 'Coming Soon',
        IMAGE_DESKTOP: ['./assets/art_products/BURC/0.d.webp?w=1200&h=1200',
            './assets/art_products/BURC/1.d.webp?w=1200&h=1200',
            './assets/art_products/BURC/2.d.webp?w=1200&h=1200'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/BURC/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/BURC/0.m.webp?w=450&h=450',
            './assets/art_products/BURC/1.m.webp?w=450&h=450',
            './assets/art_products/BURC/2.m.webp?w=450&h=450'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/BURC/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/BURC/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/BURC/-1.m.thumbnail.webp'
    },
    FLOW: {
        NAME: 'Ayçiçekleri ve Kedi',
        NAME_UPPERCASE: 'AYÇİÇEKLERİ VE KEDİ',
        YEAR: '-',
        MEDIUM: 'Oil Paint on Canvas',
        SIZE: '100cm x 98cm',
        SIGNATURE: 'Signed',
        INFO: 'Orhan Peker was born on May 27, 1927, in Trabzon and passed away on May 28, 1978, in Istanbul.' +
          ' After completing his primary education in Trabzon, he continued his secondary education at the Austrian High School in Istanbul.' +
          ' Between 1946 and 1951, he studied painting at the Istanbul State Academy of Fine Arts in Bedri Rahmi Eyüboğlu\'s workshop and in France. In 1947, he co-founded the "Onlar Group" with his artist friends.',
        ROUTER_LINK: {
            TEXT: 'Click here to continue',
            LINK: {url: '/about-artist', artist_announcement_index: 23, current_component_index: 0},
            HREF: '/corporate?artist_announcement_index=23',
            INNER: true,
            TARGET: '_self'
        },
        ARTIST_ABOUT: 'About Orhan Peker',
        ARTIST_ABOUT_UPPERCASE: 'ABOUT ORHAN PEKER',
        ARTIST_NAME: 'Orhan Peker',
        ARTIST_SHORT_NAME: 'O. Peker',
        ARTIST_BORN_DEATH: '1927 - 1978',
        CONDITION: 4.5,
        EXPERT_REPORT: './assets/art_products/FLOW/expert_report.pdf',
        INSURANCE_REPORT: './assets/art_products/FLOW/insurance_report.pdf',
        EXHIBITION_REPORT: 'Yakında Duyurulacak',
        IMAGE_DESKTOP: ['./assets/art_products/FLOW/0.d.webp?w=1164&h=1200',
            './assets/art_products/FLOW/1.d.webp?w=1200&h=1225'],
        IMAGE_DESKTOP_THUMBNAIL: ['./assets/art_products/FLOW/0.d.thumbnail.webp'],
        IMAGE_MOBILE: ['./assets/art_products/FLOW/0.m.webp?w=436&h=450',
            './assets/art_products/FLOW/1.m.webp?w=450&h=459'],
        IMAGE_MOBILE_THUMBNAIL: ['./assets/art_products/FLOW/0.m.thumbnail.webp'],
        IMAGE_BACKGROUND_DESKTOP: './assets/art_products/FLOW/-1.d.webp',
        IMAGE_BACKGROUND_MOBILE: './assets/art_products/FLOW/-1.m.thumbnail.webp'
    },

    ...test_art_products_config_en,
};
