<!--<div *ngIf="managerService.environment.app && managerService.config_ready | managerPipe" [style.height.px]="managerService.show_sign_up_or_login ? 104 : 56"></div>-->

<!--<app-header></app-header>-->

<div #app_popup *ngIf="!managerService.environment.app" [@appGrow]="app_grow" class="d-none w-100">
  <div class="d-flex flex-column py-2">
    <div class="mx-auto mb-2"
         style="font-family: Roboto, sans-serif; font-size: 12px;">{{ managerService.config.HEADER.TO_INSTALL_APP }}
    </div>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <div (click)="app_grow='close';" class="mr-2"
           style="font-family: Roboto, sans-serif; font-size: 12px; color: var(--artiox-blue);"
           type="button">{{ managerService.config.HEADER.LATER }}
      </div>
      <a class="btn-native sign-up-login-button py-1 px-3 ml-4"
         href="{{managerService.config.MISC.ONELINK | managerPipe}}"
         style="font-family: Roboto, sans-serif; font-size: 12px; color: #ffffff; border-radius: 20px; letter-spacing: normal"
         type="button">{{ managerService.config.HEADER.INSTALL | managerPipe }}</a>
    </div>
  </div>
</div>

<router-outlet
  *ngIf="managerService.config_ready && managerService.client_socket.connected && managerService.client_socket.id | managerPipe"></router-outlet>

<app-connection-fail-mobile
  *ngIf="!managerService.config_ready || !(managerService.client_socket.connected && managerService.client_socket.id) | managerPipe">
</app-connection-fail-mobile>

<!--<app-footer></app-footer>-->


<div #cookie_popup class="d-none fixed-bottom p-4" id="cookie-popup">
  <div class="w-100 mw-100 text-white bg-dark">
    <div class="d-flex flex-column p-4">
      <h4 [innerHTML]="managerService.config.COOKIE.HEADER | managerPipe"></h4>
      <p *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
         [innerHTML]="managerService.config.COOKIE.BODY | managerPipe: managerService.mpa_template.SET_HREF_SOURCE"
         class="h6 font-weight-light"></p>
      <p *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
         [innerHTML]="managerService.config.COOKIE.BODY_SHORT | managerPipe: managerService.mpa_template.SET_HREF_SOURCE"
         class="h6 font-weight-light"></p>
      <div class="ml-auto">
        <button (click)="setCookiesAccepted(false)"
                [innerHTML]="managerService.config.COOKIE.DENY | managerPipe"
                class="btn btn-outline-light mr-3"
                type="button"></button>
        <button (click)="setCookiesAccepted(true)"
                [innerHTML]="managerService.config.COOKIE.ACCEPT | managerPipe"
                class="btn btn-light mr-2"
                type="button"></button>
      </div>
    </div>
  </div>
</div>

<div class="position-relative" id="snack-bar-container" style="z-index: 1000;"></div>

<div aria-hidden="true" class="pswp" id="photoswipe" role="dialog">
  <div class="pswp__bg"></div>
  <div class="pswp__scroll-wrap">
    <div class="pswp__container">
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
      <div class="pswp__item"></div>
    </div>
    <div class="pswp__ui pswp__ui--hidden pt-4">

      <div class="pswp__top-bar pt-5">
        <div class="pswp__counter pt-5"></div>
        <button class="pswp__button pswp__button--close" title="Close (Esc)"></button>
        <button class="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
        <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
        <div class="pswp__preloader pt-5">
          <div class="pswp__preloader__icn">
            <div class="pswp__preloader__cut">
              <div class="pswp__preloader__donut"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
        <div class="pswp__share-tooltip"></div>
      </div>
      <button class="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
      </button>
      <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
      </button>
      <div class="pswp__caption">
        <div class="pswp__caption__center"></div>
      </div>
    </div>
  </div>
</div>


<app-modal class="d-none" id="login-modal">
  <app-login-desktop *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
                     class="p-5"
                     modal_id="login-modal"></app-login-desktop>
  <app-login-mobile *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
                    class="p-3"
                    modal_id="login-modal"></app-login-mobile>
</app-modal>

<app-modal [time_threshold]="2000" class="d-none" id="activation-mail-modal">
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
       class="p-5 w-100 d-flex flex-column align-items-center justify-content-center"
       style=" background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
    <div class="d-flex w-100 justify-content-end">
      <svg (click)="managerService.closeModal('activation-mail-modal')" style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;">
        <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="white"/>
      </svg>
    </div>
    <div class="w-100 d-flex flex-column align-items-center justify-content-center"
         style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
      <div class="p-3"
           style="font-family: bebas-neue-regular, sans-serif;font-size: 44px; font-weight: bold; line-height: 1.2; letter-spacing: 4.5px; color: #f4f5f9;">
        {{ managerService.config.SIGN_UP.SIGN_UP_SUCCESSFUL | managerPipe }}
      </div>
      <div
        style="font-family: Roboto, sans-serif; font-size: 16px; line-height: 1.6; letter-spacing: 1.5px; color: #ffffff;">
        {{ managerService.config.SIGN_UP.ACTIVATION_MAIL | managerPipe }}
      </div>
      <div class="w-100 d-flex flex-row align-items-center justify-content-center m-3">

        <svg style="height:200px; width:200px;">
          <use [attr.xlink:href]='"./assets/images/activation_mail.svg#activation_mail"' class="h-100 w-100"
               fill="#f4f5f9"/>
        </svg>
        <span
          style="height: 125px; width: 2px; min-width: 2px; margin: auto 50px; border-radius:2px; background-color: #f4f5f9;"></span>
        <div class="d-flex flex-column align-items-start justify-content-center" style="font-family: Roboto, sans-serif;
        font-size: 14px; font-style: italic; line-height: 1.8; color: #ffffff;">
          <div
            style="  font-weight: bold; font-style: normal;">{{ managerService.config.SIGN_UP.ACTIVATION_MAIL_NOT_SENT | managerPipe }}
          </div>
          <div *ngFor="let check of (managerService.config.SIGN_UP.ACTIVATION_MAIL_NOT_SENT_CHECK_LIST | managerPipe)">
            {{ check }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
       style="max-width: 320px;">
    <div class="p-4 w-100 d-flex flex-column align-items-center justify-content-center"
         style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
      <div class="w-100 text-center"
           style="font-family: 'Roboto Condensed', sans-serif; font-size: 18px; line-height: 1.2; letter-spacing: 0.5px; color: #f4f5f9;">
        {{ managerService.config.SIGN_UP.SIGN_UP_SUCCESSFUL | managerPipe }}
      </div>
      <div class="w-100 d-flex flex-row align-items-center justify-content-center m-3">
        <svg style="height:75px; width:75px;">
          <use [attr.xlink:href]='"./assets/images/activation_mail.svg#activation_mail"' class="h-100 w-100"
               fill="#f4f5f9"/>
        </svg>
        <span
          style="height: 50px; width: 1px; min-width: 1px; margin: auto 15px; border-radius:2px; background-color: #f4f5f9;"></span>
        <div class="w-50"
             style="font-family: Roboto, sans-serif; font-size: 12px; line-height: 1.4; letter-spacing: 0.25px; color: #ffffff;">
          {{ managerService.config.SIGN_UP.ACTIVATION_MAIL | managerPipe }}
        </div>
      </div>
      <div class="d-flex flex-column align-items-start justify-content-center" style="font-family: Roboto, sans-serif;
        font-size: 12px; font-style: italic; line-height: 1.8; color: #ffffff;">
        <div
          style="font-weight: bold; font-style: normal;">{{ managerService.config.SIGN_UP.ACTIVATION_MAIL_NOT_SENT | managerPipe }}
        </div>
        <div *ngFor="let check of (managerService.config.SIGN_UP.ACTIVATION_MAIL_NOT_SENT_CHECK_LIST | managerPipe)">
          {{ check }}
        </div>
      </div>
    </div>
  </div>
</app-modal>

<app-modal class="d-none" id="forgot-password-modal">
  <app-forgot-password-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="forgot-password-modal"></app-forgot-password-desktop>
  <app-forgot-password-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="forgot-password-modal"></app-forgot-password-mobile>
</app-modal>


<app-modal class="d-none" id="fiat-deposit-rules-modal">
  <app-fiat-deposit-rules-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="fiat-deposit-rules-modal"></app-fiat-deposit-rules-desktop>
  <app-fiat-deposit-rules-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE" class="p-3"
    modal_id="fiat-deposit-rules-modal"
    style="max-width: 300px;"></app-fiat-deposit-rules-mobile>
</app-modal>

<app-modal class="d-none" id="crypto-deposit-rules-modal">
  <app-crypto-deposit-rules-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="crypto-deposit-rules-modal"></app-crypto-deposit-rules-desktop>
  <app-crypto-deposit-rules-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE" class="p-3"
    modal_id="crypto-deposit-rules-modal"
    style="max-width: 300px;"></app-crypto-deposit-rules-mobile>
</app-modal>

<app-modal class="d-none" id="ethereum-deposit-rules-modal">
    <app-ethereum-deposit-rules-desktop
            *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
            modal_id="ethereum-deposit-rules-modal"></app-ethereum-deposit-rules-desktop>
    <app-ethereum-deposit-rules-mobile
            *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE" class="p-3"
            modal_id="crypto-deposit-rules-modal"
            style="max-width: 300px;"></app-ethereum-deposit-rules-mobile>
</app-modal>

<app-modal class="d-none" id="add-bank-account-modal">
  <app-add-bank-account-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="add-bank-account-modal"></app-add-bank-account-desktop>
  <app-add-bank-account-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="add-bank-account-modal"
    style="background-color: #21232d"></app-add-bank-account-mobile>
</app-modal>

<app-modal class="d-none" id="add-crypto-account-modal">
  <app-add-crypto-account-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="add-crypto-account-modal"></app-add-crypto-account-desktop>
  <app-add-crypto-account-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="add-crypto-account-modal" style="background-color: #21232d"></app-add-crypto-account-mobile>
</app-modal>

<app-modal [background_touch_close_disabled]="true" class="d-none" id="fiat-withdrawal-request-modal">
  <app-fiat-withdrawal-request-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="fiat-withdrawal-request-modal"></app-fiat-withdrawal-request-desktop>
  <app-fiat-withdrawal-request-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="fiat-withdrawal-request-modal"></app-fiat-withdrawal-request-mobile>
</app-modal>

<app-modal [background_touch_close_disabled]="true" class="d-none" id="crypto-withdrawal-request-modal">
  <app-crypto-withdrawal-request-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="crypto-withdrawal-request-modal"></app-crypto-withdrawal-request-desktop>
  <app-crypto-withdrawal-request-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="crypto-withdrawal-request-modal"></app-crypto-withdrawal-request-mobile>
</app-modal>

<app-modal [background_touch_close_disabled]="true" class="d-none" id="ethereum-withdrawal-request-modal">
  <app-ethereum-withdrawal-request-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="ethereum-withdrawal-request-modal"></app-ethereum-withdrawal-request-desktop>
  <app-ethereum-withdrawal-request-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="ethereum-withdrawal-request-modal"></app-ethereum-withdrawal-request-mobile>
</app-modal>


<app-modal class="d-none" id="currency-converter-modal">
  <app-currency-converter-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="currency-converter-modal"></app-currency-converter-desktop>
  <app-currency-converter-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="currency-converter-modal"></app-currency-converter-mobile>
</app-modal>


<app-modal [drawer]="true" class="d-none" id="auction-bid-report-modal">
  <app-auction-bid-report-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-bid-report-modal"></app-auction-bid-report-desktop>
  <app-auction-bid-report-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-bid-report-modal"></app-auction-bid-report-mobile>
</app-modal>

<app-modal [drawer]="true" [background_touch_close_disabled]="true" class="d-none" id="auction-agreement-modal">
  <app-auction-agreement-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-agreement-modal"></app-auction-agreement-desktop>
  <app-auction-agreement-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-agreement-modal"></app-auction-agreement-mobile>
</app-modal>

<!-- todo user-agreement-modal -->
<app-modal [drawer]="true" [background_touch_close_disabled]="true" class="d-none" id="user-agreement-modal">
  <app-user-agreement-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="user-agreement-modal"></app-user-agreement-desktop>
  <app-user-agreement-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="user-agreement-modal"></app-user-agreement-mobile>
</app-modal>

<app-modal [drawer]="true" class="d-none" id="account-level-modal">
  <app-account-level-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="account-level-modal"></app-account-level-desktop>
  <app-account-level-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="account-level-modal"></app-account-level-mobile>
</app-modal>

<app-modal [drawer]="true" [time_threshold]="600" class="d-none" id="auction-bid-approved-modal">
  <app-auction-bid-approved-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-bid-approved-modal"></app-auction-bid-approved-desktop>
  <app-auction-bid-approved-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-bid-approved-modal"></app-auction-bid-approved-mobile>
</app-modal>

<app-modal [drawer]="true" class="d-none" id="auction-product-information-modal">
  <app-auction-product-information-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-product-information-modal"></app-auction-product-information-desktop>
  <app-auction-product-information-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-product-information-modal"></app-auction-product-information-mobile>
</app-modal>

<app-modal [drawer]="true" class="d-none" id="auction-bid-rules-modal">
  <app-auction-bid-rules-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-bid-rules-modal"></app-auction-bid-rules-desktop>
  <app-auction-bid-rules-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-bid-rules-modal"></app-auction-bid-rules-mobile>
</app-modal>

<app-modal [drawer]="true" class="d-none" id="auction-duration-rules-modal">
  <app-auction-duration-rules-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-duration-rules-modal"></app-auction-duration-rules-desktop>
  <app-auction-duration-rules-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-duration-rules-modal"></app-auction-duration-rules-mobile>
</app-modal>

<!--<app-modal class="d-none" id="app-auction-price-estimation-modal">-->
<!--    <app-auction-price-estimation-desktop-->
<!--        *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"-->
<!--        modal_id="app-auction-price-estimation-modal"></app-auction-price-estimation-desktop>-->
<!--    <app-auction-price-estimation-mobile-->
<!--        *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"-->
<!--        modal_id="app-auction-price-estimation-modal"></app-auction-price-estimation-mobile>-->
<!--</app-modal>-->

<app-modal [drawer]="true" class="d-none" id="auction-share-modal">
  <app-auction-share-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="auction-share-modal"></app-auction-share-desktop>
  <app-auction-share-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="auction-share-modal"></app-auction-share-mobile>
</app-modal>


<app-modal class="d-none" id="add-delivery-address-modal">
  <app-add-delivery-address-desktop *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
                                    modal_id="add-delivery-address-modal"></app-add-delivery-address-desktop>
  <app-add-delivery-address-mobile *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
                                   modal_id="add-delivery-address-modal"></app-add-delivery-address-mobile>
</app-modal>

<app-modal class="d-none" id="add-billing-address-modal">
  <app-add-billing-address-desktop *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
                                   modal_id="add-billing-address-modal"></app-add-billing-address-desktop>
  <app-add-billing-address-mobile *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
                                  modal_id="add-billing-address-modal"></app-add-billing-address-mobile>
</app-modal>

<app-modal class="d-none" id="delete-address-modal">
  <app-delete-address-desktop *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
                              modal_id="delete-address-modal"></app-delete-address-desktop>
  <app-delete-address-mobile *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
                             modal_id="delete-address-modal"></app-delete-address-mobile>
</app-modal>

<app-modal class="d-none" id="approve-payment-modal">
  <app-approve-payment-desktop *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
                               modal_id="approve-payment-modal"></app-approve-payment-desktop>
  <app-approve-payment-mobile *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
                              modal_id="approve-payment-modal"></app-approve-payment-mobile>
</app-modal>


<app-modal [time_threshold]="600" class="d-none" id="one-pager-modal">
  <app-one-pager-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
    modal_id="one-pager-modal"></app-one-pager-desktop>
  <app-one-pager-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
    modal_id="one-pager-modal"></app-one-pager-mobile>
</app-modal>

<app-modal class="d-none" id="delisting-modal" [time_threshold]="1000">
  <app-delisting-desktop *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
                         modal_id="delisting-modal"></app-delisting-desktop>
  <app-delisting-mobile *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
                        modal_id="delisting-modal"
                        style="max-width: 300px;"></app-delisting-mobile>
</app-modal>


<app-modal *ngIf="managerService.config_ready | managerPipe" [time_threshold]="300" class="d-none" id="loading-modal">
  <div class="d-flex flex-row align-items-center justify-content-center p-3" style="background: #fcfdff!important;">
    <video #loading_video (canplay)="loading_video.play()" (loadedmetadata)="loading_video.muted=true" autoplay loop
           muted playsinline style="height:60px; width: 60px;">
      <source [src]="managerService.config.MISC.LOADING_ANIM_MOBILE_WEBM | managerPipe" class="w-100"
              type='video/webm; codecs="vp8.0"'/>
      <source [src]="managerService.config.MISC.LOADING_ANIM_MOBILE_MP4 | managerPipe" class="w-100"
              type='video/mp4;'/>
    </video>
  </div>
</app-modal>

<app-modal class="d-none" id="announcement-modal">
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
       style="max-width: 800px;">

    <div class="p-5 w-100 d-flex flex-column align-items-center justify-content-center"
         style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
      <div class="d-flex w-100 justify-content-end">
        <svg (click)="managerService.closeModal('announcement-modal')" style="margin-top: -16px; margin-right: -8px; height: 18px; width: 18px;">
          <use [attr.xlink:href]='"./assets/images/exit_icon.svg#exit_icon"' fill="white"/>
        </svg>
      </div>
      <div class="p-3 text-center"
           style="font-family: Roboto, sans-serif; font-size: 30px; line-height: 1.2; letter-spacing: 3px; color: #ffffff;">
        {{ managerService.config.ANNOUNCEMENT_POPUP.HEADING | managerPipe }}
      </div>
      <div [innerHTML]="managerService.config.ANNOUNCEMENT_POPUP.BODY | managerPipe" class="px-5 py-3 text-center"
           style="font-family: Roboto, sans-serif; font-size: 17px; font-weight: 300; line-height: 1.5; letter-spacing: 1.75px; color: #ffffff;">
      </div>
    </div>
  </div>
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
       style="max-width: 300px;">
    <div class="p-3 w-100 d-flex flex-column align-items-center justify-content-center"
         style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
      <div class="p-3"
           style="font-family: Roboto, sans-serif; font-size: 17px; line-height: 1.2; letter-spacing: 0.7px; text-align: center; color: #ffffff;">
        {{ managerService.config.ANNOUNCEMENT_POPUP.HEADING | managerPipe }}
      </div>
      <div [innerHTML]="managerService.config.ANNOUNCEMENT_POPUP.BODY | managerPipe" class="text-center"
           style="font-family: Roboto, sans-serif; font-size: 12px; font-weight: 300; line-height: 1.5; letter-spacing: 0.25px; color: #ffffff;">
      </div>
    </div>
  </div>
</app-modal>

<app-modal *ngIf="managerService.config_ready | managerPipe" [time_threshold]="4000" class="d-none"
           id="maintenance-modal" style="background-color: rgba(0,0,0,0)">
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP"
       style="max-width:800px;">
    <div class=" w-100 d-flex flex-column align-items-center justify-content-center"
         style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
      <video #video (canplay)="video.play()" (loadedmetadata)="video.muted=true" autoplay loop
             muted playsinline style="height:600px; width: 600px; min-width: 300px;">
        <source [src]="managerService.config.ANNOUNCEMENT_POPUP.SRC | managerPipe" class="w-100"
                type='video/webm; codecs="vp8.0"'/>
        <source [src]="managerService.config.ANNOUNCEMENT_POPUP.SRC_MP4 | managerPipe" class="w-100"
                type='video/mp4;'/>
      </video>
    </div>
  </div>
  <div *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE"
       style="max-width:300px;">
    <div class="w-100 d-flex flex-column align-items-center justify-content-center"
         style="background-image: radial-gradient(circle 1000px at 72.3% 1991.3%, #1e2028 0%, #21232d 100%);">
      <video #video (canplay)="video.play()" (loadedmetadata)="video.muted=true" autoplay loop
             muted playsinline style="width: 100%; min-width: 300px;">
        <source [src]="managerService.config.ANNOUNCEMENT_POPUP.SRC | managerPipe" class="w-100"
                type='video/webm; codecs="vp8.0"'/>
        <source [src]="managerService.config.ANNOUNCEMENT_POPUP.SRC_MP4 | managerPipe" class="w-100"
                type='video/mp4;'/>
      </video>
    </div>
  </div>
</app-modal>

<app-modal #test_environment_warning *ngIf="managerService.environment.test | managerPipe" [close_disabled]="true"
           id="test-environment-warning">
  <app-test-environment-warning-desktop
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_DESKTOP">
  </app-test-environment-warning-desktop>
  <app-test-environment-warning-mobile
    *ngIf="managerService.view_port | managerPipe: managerService.mpa_template.RENDER_MOBILE">
  </app-test-environment-warning-mobile>
</app-modal>


<!--<app-modal-->
<!--        *ngIf="managerService.environment.app && managerService.show_connection_problem_modal && !managerService.maintenance | managerPipe"-->
<!--        id="connection-problem-modal" [close_disabled]="true">-->
<!--    <div *ngIf="managerService.config_ready | managerPipe"-->
<!--         class="d-flex flex-column align-items-center"-->
<!--         style="width:300px; height:300px; border-radius: 4px; background-color: #fcfdff;">-->
<!--        <video #video (canplay)="video.play()" (loadedmetadata)="video.muted=true" autoplay loop-->
<!--               muted playsinline style="height:80px; width: 80px; margin-top: 60px;">-->
<!--            <source [src]="managerService.config.MISC.LOADING_ANIM_MOBILE_WEBM | managerPipe" class="w-100"-->
<!--                    type='video/webm; codecs="vp8.0"'/>-->
<!--            <source [src]="managerService.config.MISC.LOADING_ANIM_MOBILE_MP4 | managerPipe" class="w-100"-->
<!--                    type='video/mp4;'/>-->
<!--        </video>-->

<!--        <div class="text-center"-->
<!--             style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: bold; letter-spacing: 0.5px; color: #404352; margin-top: 50px">-->
<!--            {{managerService.config.CONNECTION_ERROR.HEADING | managerPipe}}-->
<!--        </div>-->

<!--        <div class="text-center"-->
<!--             style="font-family: Roboto, sans-serif; font-size: 12px; color: #404352; margin-top: 15px">-->
<!--            {{managerService.config.CONNECTION_ERROR.DESCRIPTION | managerPipe}}-->
<!--        </div>-->
<!--    </div>-->
<!--</app-modal>-->

<!--<app-modal *ngIf="managerService.environment.app | managerPipe" id="update-app-modal" class="d-none"-->
<!--           [time_threshold]="6000">-->
<!--    <div class="d-flex flex-column align-items-center p-3"-->
<!--         style="width:300px; max-height:450px; border-radius: 4px; background-color: #fcfdff;">-->
<!--        <div class="text-center"-->
<!--             style="font-family: Roboto, sans-serif; font-size: 16px; font-weight: bold; letter-spacing: 0.5px; color: #404352; margin-top: 16px">-->
<!--            {{managerService.config.UPDATE_APP.HEADING | managerPipe}}-->
<!--        </div>-->
<!--        <div class="text-center"-->
<!--             style="font-family: Roboto, sans-serif; font-size: 12px; color: #404352; margin-top: 20px"-->
<!--             [innerHTML]="managerService.config.UPDATE_APP.DESCRIPTION | managerPipe">-->
<!--        </div>-->

<!--        <div *ngIf="managerService.environment.platform === 'android'"-->
<!--             class="w-100 d-flex flex-row justify-content-center align-content-center">-->
<!--            <div (click)="managerService.closeModal('update-app-modal')"-->
<!--                 class="d-block mx-2 mt-4 px-4 py-1 sign-up-login-button text-center"-->
<!--                 style="width: 120px; font-family: Roboto,sans-serif; font-size: 14px; font-weight: 300; letter-spacing: normal; color: #595e7e; background: #f4f5f9; box-shadow: none">-->
<!--                {{managerService.config.UPDATE_APP.CONTINUE | managerPipe}}-->
<!--            </div>-->
<!--            <a href="https://play.google.com/store/apps/details?id=com.artiox.artiox" target="_blank"-->
<!--               class="d-block mx-2 mt-4 px-4 py-1 sign-up-login-button text-center"-->
<!--               style="width: 120px; font-family: Roboto,sans-serif; font-size: 14px; font-weight: 500; color: #fcfdff;">-->
<!--                {{managerService.config.UPDATE_APP.UPDATE | managerPipe}}-->
<!--            </a>-->
<!--        </div>-->

<!--        <div *ngIf="managerService.environment.platform === 'ios'"-->
<!--             class="w-100 d-flex flex-row justify-content-center align-content-center">-->
<!--            <div (click)="managerService.closeModal('update-app-modal')"-->
<!--                 class="d-block mx-2 mt-4 px-4 py-1 sign-up-login-button text-center"-->
<!--                 style="width: 120px; font-family: Roboto,sans-serif; font-size: 14px; font-weight: 300; letter-spacing: normal; color: #595e7e; background: #f4f5f9; box-shadow: none">-->
<!--                {{managerService.config.UPDATE_APP.CONTINUE | managerPipe}}-->
<!--            </div>-->
<!--            <a href="itms-apps://apple.com/app/id1579037676?l=tr" target="_blank"-->
<!--               class="d-block mx-2 mt-4 px-4 py-1 sign-up-login-button text-center"-->
<!--               style="width: 120px; font-family: Roboto,sans-serif; font-size: 14px; font-weight: 400; letter-spacing: normal">-->
<!--                {{managerService.config.UPDATE_APP.UPDATE | managerPipe}}-->
<!--            </a>-->
<!--        </div>-->

<!--        <div class="px-3 mt-4 align-self-start"-->
<!--             style="font-family: Roboto, sans-serif; font-size: 16px; color: #404352;">-->
<!--            {{managerService.config.MISC.CHANGELOG_HEADER | managerPipe}}-->
<!--        </div>-->

<!--        <div *ngIf="managerService.environment.platform === 'android'"-->
<!--             class="w-100 mt-2 px-2 overflow-auto"-->
<!--             style="max-height: 200px; border: solid 1px #e4e4e4; background-color: #f4f5f9;">-->
<!--            <div style="font-family: Roboto, sans-serif; font-size: 13px; color: #404352;">-->
<!--                {{(managerService.config.MISC.CHANGELOG_ANDROID | managerPipe :managerService.mpa_template.RETURN_INDEX: 0).VERSION}}-->
<!--                :-->
<!--            </div>-->
<!--            <div [innerHTML]="(managerService.config.MISC.CHANGELOG_ANDROID | managerPipe :managerService.mpa_template.RETURN_INDEX: 0).INFO"-->
<!--                 class="mx-3" style="font-family: Roboto, sans-serif; font-size: 12px; color: #404352;">-->
<!--            </div>-->
<!--        </div>-->

<!--        <div *ngIf="managerService.environment.platform === 'ios'"-->
<!--             class="w-100 mt-2 px-2 overflow-auto"-->
<!--             style="max-height: 200px; border: solid 1px #e4e4e4; background-color: #f4f5f9;">-->
<!--            <div style="font-family: Roboto, sans-serif; font-size: 13px; color: #404352;">-->
<!--                {{(managerService.config.MISC.CHANGELOG_IOS | managerPipe :managerService.mpa_template.RETURN_INDEX: 0).VERSION}}-->
<!--                :-->
<!--            </div>-->
<!--            <div [innerHTML]="(managerService.config.MISC.CHANGELOG_IOS | managerPipe :managerService.mpa_template.RETURN_INDEX: 0).INFO"-->
<!--                 class="mx-3" style="font-family: Roboto, sans-serif; font-size: 12px; color: #404352;">-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</app-modal>-->

<!--<app-modal *ngIf="managerService.environment.app | managerPipe" id="generic-modal" class="d-none"-->
<!--           [time_threshold]="managerService.config.GENERIC_MODAL.TIME_THRESHOLD"-->
<!--           [close_disabled]="managerService.config.GENERIC_MODAL.CLOSE_DISABLED">-->
<!--    <div class="d-flex flex-column align-items-center p-3"-->
<!--         style="width:300px; max-height:450px; border-radius: 4px; background-color: #fcfdff;"-->
<!--         [innerHTML]="managerService.config.GENERIC_MODAL.INNER_HTML | managerPipe: managerService.mpa_template.BYPASS_SECURITY_TRUST_HTML: managerService.language_pipe">-->
<!--    </div>-->
<!--</app-modal>-->

<!--<div *ngIf="managerService.environment.app | managerPipe"-->
<!--     style="height: calc(56px + env(safe-area-inset-bottom));"></div>-->
